import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { Spinner } from "react-bootstrap";
import {
  getContentPagesInfo,
  updateContentPagesInfo,
} from "../../../redux/slices/web";
import { useWebSelector } from "../../../redux/selector/web";
import { CKEditor } from "ckeditor4-react";
import { toast } from "react-toastify";

const ContentPages = () => {
  document.title = "Content Pages";
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [activeTab, setActiveTab] = useState("0");
  const [btnLoading, setBtnLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [isEditorReady, setIsEditorReady] = useState(false);

  // Separate states for each tab's content
  const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
  const [aboutUsContent, setAboutUsContent] = useState("");
  const [originalPrivacyPolicyContent, setOriginalPrivacyPolicyContent] =
    useState("");
  const [originalAboutUsContent, setOriginalAboutUsContent] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    type: "",
  });

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Check if update button should be visible
  const isUpdateVisible =
    (activeTab === "0" &&
      privacyPolicyContent !== originalPrivacyPolicyContent) ||
    (activeTab === "1" && aboutUsContent !== originalAboutUsContent);

  // Fetch data based on tab
  const handleChange = (slug) => {
    setActiveTab(slug);
    let params = { type: slug };
    dispatch(
      getContentPagesInfo({
        ...params,
        cb(res) {
          if (res.status) {
            const pageData = res?.data?.data?.page;
            setDescription(pageData);

            setFormData({
              title: pageData?.title || "",
              type: pageData?.page_type || "",
            });

            if (slug === "0") {
              setPrivacyPolicyContent(pageData?.description || "");
              setOriginalPrivacyPolicyContent(pageData?.description || "");
            } else if (slug === "1") {
              setAboutUsContent(pageData?.description || "");
              setOriginalAboutUsContent(pageData?.description || "");
            }
          }
        },
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = (id, flag) => {
    const updatedDescription =
    flag === "0" ? privacyPolicyContent : aboutUsContent;
    if (!updatedDescription) {
      showToast("Please enter description");
      return;
    }

    const params = { id, description: updatedDescription };

    setBtnLoading(true);
    dispatch(
      updateContentPagesInfo({
        ...params,
        cb(res) {
          if (res.status) {
            setBtnLoading(false);
            handleChange(flag);
          }
        },
      })
    );
  };

  useEffect(() => {
    handleChange("0");
    setIsEditorReady(true);
    document.title = "infoPages";
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Content Page</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="infoPageBtn">
            <button
              onClick={() => handleChange("0")}
              type="button"
              className={`secondaryBtnGrey ${
                activeTab === "0" ? "infoBtn__ active" : "infoBtn__"
              }`}
            >
              Privacy Policy
            </button>
            <button
              onClick={() => handleChange("1")}
              type="button"
              className={`secondaryBtnGrey ${
                activeTab === "1" ? "infoBtn__ active" : "infoBtn__"
              }`}
            >
              About Us
            </button>
          </div>
          <div className="adminInfo">
            {activeTab === "0" && (
              <>
                {loading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="admintermBanner_" id="Terms">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="labelTxt">Title</label>
                            <input
                              disabled
                              style={{ cursor: "not-allowed" }}
                              type="text"
                              className="inputBox"
                              name="title"
                              placeholder="Title"
                              value={formData?.title}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label className="labelTxt">Page Type</label>
                            <input
                              disabled
                              style={{ cursor: "not-allowed" }}
                              type="text"
                              className="inputBox"
                              name="type"
                              placeholder="Type"
                              value={formData?.type}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div> */}
                        <div className="contentOuter">
                          <label className="labelTxt">Description</label>
                          {isEditorReady && (
                            <CKEditor
                              initData={privacyPolicyContent}
                              config={{
                                versionCheck: false,
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                  "TableToolbar",
                                  "image",
                                ],
                                toolbarLocation: "top",
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                setPrivacyPolicyContent(data);
                              }}
                            />
                          )}
                        </div>
                        
                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="courseFoot">
                                <button
                                  className="saveBtn"
                                  type="button"
                                  onClick={() =>
                                    handleUpdate(description?.id, "0")
                                  }
                                >
                                  {btnLoading ? (
                                    <span>Loading</span>
                                  ) : (
                                    <span>Update</span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {/* About Us Content */}
            {activeTab === "1" && (
              <>
                {loading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="admintermBanner_" id="Terms">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            {/* <h3 className="medText"> {formData?.title}</h3> */}
                            <label className="labelTxt">Title</label>
                            <input
                              disabled
                              type="text"
                              className="inputBox"
                              style={{ cursor: "not-allowed" }}
                              name="title"
                              placeholder="Title"
                              value={formData?.title}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label className="labelTxt">Page Type</label>
                            <input
                              disabled
                              style={{ cursor: "not-allowed" }}
                              type="text"
                              className="inputBox"
                              name="type"
                              placeholder="Type"
                              value={formData?.type}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div> */}
                        <div className="contentOuter">
                          <label className="labelTxt">Description</label>
                          {isEditorReady && (
                            <CKEditor
                              initData={aboutUsContent}
                              config={{
                                versionCheck: false,
                                removePlugins: [
                                  "EasyImage",
                                  "ImageUpload",
                                  "MediaEmbed",
                                  "Table",
                                  "TableToolbar",
                                  "image",
                                ],
                                toolbarLocation: "top",
                              }}
                              onChange={(event) => {
                                const data = event.editor.getData();
                                setAboutUsContent(data);
                              }}
                            />
                          )}
                        </div>
                       
                          <div className="row mt-4">
                            <div className="col-12">
                              <div className="courseFoot">
                                <button
                                  className="saveBtn"
                                  type="button"
                                  onClick={() =>
                                    handleUpdate(description?.id, "1")
                                  }
                                >
                                  {btnLoading ? (
                                    <span>Loading</span>
                                  ) : (
                                    <span>Update</span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ContentPages;
